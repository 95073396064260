import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// *******************************************************************************
// NgBootstrap
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// Core

import { AppRoutingModule } from './app.routes.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from '../templates/not-found/not-found.component';
import { AppService } from './app.service';
import { LayoutModule } from '../templates/layout/layout.module';

// *******************************************************************************
// App
import { ToastrModule } from 'ngx-toastr';
import { environment } from '@environments/environment';

// *******************************************************************************
// Firebase
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire';
import { NgbDateFRParserFormatter } from './shared/+utilities/+ngb-date-formatter/ngb-date-parser-formatter';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateCmp } from './shared/+utilities/+spinners/+block-ui/block-ui-template.component';

// *******************************************************************************
// Config

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    BlockTemplateCmp
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot({ preventDuplicates: true, timeOut: 5000 }),

    // App
    AppRoutingModule,
    LayoutModule,

    // Firebase
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebase),

    // BlockUI
    BlockUIModule.forRoot({
      template: BlockTemplateCmp
    })
  ],

  providers: [
    Title,
    AppService,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },

  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
