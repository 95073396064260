<div class="layout-wrapper layout-qdup-front layout-2" [ngClass]="{'layout-loading': !initialized}">
  <div class="layout-inner">
    <app-layout-qdup-sidenav-front></app-layout-qdup-sidenav-front>

    <div class="layout-container container pl-0">
      <app-layout-qdup-navbar></app-layout-qdup-navbar>

      <div class="layout-content">
        <div class="container pt-3 router-transitions flex-grow-1">
          <router-outlet></router-outlet>
        </div>

        <app-layout-footer></app-layout-footer>
      </div>
    </div>
  </div>
</div>
<div class="layout-overlay" (click)="closeSidenav()"></div>
