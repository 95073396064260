import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject} from 'rxjs';
import { CLAIMS } from '../constants/claims.const';
import firebase from 'firebase/app';
import * as _ from 'lodash';

/**
 * Authentication functions pertaining to the currently logged in user. To check the status of other user accounts, use the `UserService` located in the +users feature folder.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userDetails$: BehaviorSubject<firebase.User> = new BehaviorSubject(null);;
  userTokenResult$: BehaviorSubject<firebase.auth.IdTokenResult> = new BehaviorSubject(null);

  constructor(private firebaseAuth: AngularFireAuth) {
    this.firebaseAuth.user.subscribe(
      (d) => {
        this.userDetails$.next(d);

        if (!d) {
          localStorage.clear(); //Reset any localstorage values if there isn't a logged in user
        } else {

          // Fetch the user's ID Token Result for Claims checking
          d.getIdTokenResult().then(
            (tokenResult) => {
              this.userTokenResult$.next(tokenResult);
            }
          );
        }
      }
    );
  }

  /**
   * Check if the user is logged in.
   */
  isLoggedIn() {
    if (this.userDetails$.value == null) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Get the current user auth account
   */
  getCurrentUser() {
    return this.userDetails$.value;
  }

  getCurrentUserId() {
    return this.userDetails$.value.uid;
  }

  /**
   * Get the current user auth account by Subscription
   */
  getCurrentUser$() {
    return this.userDetails$;
  }

  /**
   * Clear localstorage and sign the user out
   */
  logout() {
    localStorage.clear();
    return this.firebaseAuth.signOut();
  }

  /**
   * Register a new basic account with no claims from public registration page.
   * @param email Email Address
   * @param pass Password
   */
  register(email: string, pass: string): Promise<firebase.auth.UserCredential> {
    return this.firebaseAuth.createUserWithEmailAndPassword(email, pass);
  }

  /**
   * Link a basic user account to Google OAuth.
   */
  linkGoogleAccount() {
    const provider = new firebase.auth.GoogleAuthProvider();

    // trigger redirect
    this.userDetails$.value.linkWithPopup(provider).then(
      (credential) => {
        this.userDetails$.next(credential.user);

        const googleProvider = _.find(credential.user.providerData, { providerId: 'google.com' });

        credential.user.updateProfile({ displayName: credential.user.displayName, photoURL: googleProvider.photoURL });
      }
    );
  }

  /**
   * Unlink a Google OAuth account from Basic account.
   */
  unlinkGoogleAccount() {
    const provider = new firebase.auth.GoogleAuthProvider();

    return this.userDetails$.value.unlink(provider.providerId);
  }

  /**
   * Authenticate a basic user account
   * @param email email address
   * @param pass password
   */
  login(email: string, pass: string): Promise<firebase.auth.UserCredential> {
    return this.firebaseAuth.signInWithEmailAndPassword(email, pass);
  }

  /**
   * Prompt authentication of Google Oauth
   */
  loginWithGoogle(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();

    return this.firebaseAuth.signInWithPopup(provider);
  }

  /**
   * Prompt password reset email via automated Firebase sender.
   * @param email email address of account user
   */
  triggerResetPassword(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

  /**
   * Confirm email address from generated Firebase email.
   * @param code Confirmation code from Firebase Accounts
   */
  confirmEmail(code): Promise<void> {
    return this.firebaseAuth.applyActionCode(code);
  }

  /**
   * Commit a new password for the given user.
   * @param code Action code from Firebase auth
   * @param newPassword User's new password
   */
  resetPassword(code, newPassword): Promise<void> {
    return this.firebaseAuth.confirmPasswordReset(code, newPassword);
  }

  /**
   * Trigger a forced refresh of the idTokenResult for the currently logged in user
   */
  getUserIdToken(): Promise<firebase.auth.IdTokenResult> {
    if (this.userDetails$.value === null) return new Promise((res, rej) => { rej(null); });
    return this.userDetails$.value.getIdTokenResult(true);
  }

  /**
   * Check if user has ANY of the given claims
   * @param claims Claims list to check against
   */
  userHasClaims(claims: CLAIMS[]) {
    if (this.userTokenResult$.value === null) return false;

    let pass = true;

    _.each(claims, (claim) => {
      if (pass) {
        pass = !!this.userTokenResult$.value.claims[claim];
      }
    });

    return pass;
  }

  /**
   * Get the value of a given claim.
   * @param claim Auth Claim
   */
  getClaimValue(claim: string) {
    if (this.userTokenResult$.value !== null)
      return this.userTokenResult$.value.claims[claim];

    return false;
  }
}
