import { Component, Input, HostBinding, OnDestroy } from '@angular/core';
import { AppService } from '@app/app.service';
import { LayoutService } from '../layout.service';
import { AuthService } from '@app/+auth/services/auth.service';
import * as firebase from 'firebase/firebase-auth';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout-navbar-admin',
  templateUrl: './layout-navbar-admin.component.html',
  styles: [':host { display: block; } .navbar { height: 60px; }']
})
export class LayoutNavbarAdminComponent implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  isExpanded = false;
  isRTL: boolean;
  user: firebase.User;

  accountRoute: string[];

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') hostClassMain = true;

  constructor(private appService: AppService, private layoutService: LayoutService, private authService: AuthService) {
    this.isRTL = appService.isRTL;

    this.accountRoute = ['/account/my-profile'];

    this.authService.getCurrentUser$().pipe(takeUntil(this.destroyed$)).subscribe(
      (user) => {
        this.user = user;
      }
    );
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
