<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x"
     [ngClass]="currentBg()">

   <!-- Brand -->
   <!-- <a routerLink="/" class="navbar-brand">Angular Starter</a> -->

   <!-- Sidenav toggle -->
   <div class="navbar-nav d-lg-none d-xl-none align-items-lg-center mr-auto mr-lg-4"
        *ngIf="sidenavToggle">
      <a class="nav-item nav-link px-0"
         href="javascript:void(0)"
         (click)="toggleSidenav()">
         <i class="fas fa-bars text-large align-middle"></i>
      </a>
   </div>


   <div class="navbar-nav align-items-lg-center ml-auto">
      <div ngbDropdown
           [placement]="isRTL ? 'bottom-left' : 'bottom-right'"
           class="demo-navbar-notifications nav-item mr-lg-3">
      </div>

      <a class="ml-auto"
         *ngIf="!user"
         [routerLink]="accountRoute"><i class="fas fa-sign-in-alt"></i> Login</a>
      <div ngbDropdown
           *ngIf="user"
           [placement]="isRTL ? 'bottom-left' : 'bottom-right'"
           class="demo-navbar-user nav-item">
         <a ngbDropdownToggle
            class="nav-link"
            href="javascript:void(0)">
            <span class="d-inline-flex align-items-center align-middle">
               <div [style]="'background-image: url(\'' + user?.photoURL + '\')'"
                    alt
                    class="d-block top-bar-user-image rounded-circle">
                    </div>
               <span class="px-1 ml-1  ml-lg-0">{{user?.displayName}}</span>


            </span>
         </a>
         <div ngbDropdownMenu
              [class.dropdown-menu-right]="!isRTL">
              <a [routerLink]="'/my-profile'" class="dropdown-item"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</a>
              <!--<a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</a>-->
              <a [routerLink]="'/my-account'" class="dropdown-item"><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</a>
            <div class="dropdown-divider d-none"></div>
            <a [routerLink]="['/', 'identity', 'logout']"
               class="dropdown-item"><i class="fas fa-sign-out-alt text-danger"></i> &nbsp; Log Out</a>
         </div>
      </div>
   </div>

</nav>
