<nav class="footer"
     [ngClass]="currentBg()">
  <div class="container-fluid container-p-x pb-3">
    <div class="row">
      <div class="col pt-3">
        <small class="">Updated: <b class="">{{currentVersion | date: 'short'}}</b></small>
      </div>
      <div class="col text-right">
        <small class=""></small>
      </div>
    </div>


  </div>

</nav>
