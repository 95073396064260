import { ACTOR_ROUTE_NAMES } from './+actor/routes/actor.routes.names';
import { CLAIMS } from './+auth/constants/claims.const';
import { PRODUCER_ROUTE_NAMES } from './+producer/routes/producer.routes.names';
import { STUDIO_ROUTE_NAMES } from './+studio/routes/studio.routes.names';
import { APP_ROUTE_NAMES } from './app.routes.names';

const MENU_ITEM_TYPES = {
  HEADER: 'header',
  ROUTE: 'route',
  DIVIDER: 'divider',
  SUBMENU: 'submenu'
};

class MenuItemVM {
  type: string;
  text: string;
  uri?: string;
  icon?: string;
  activeUriSegment?: string;
  exact?: boolean;

  childRoutes?: MenuItemVM[];

  claims?: CLAIMS[];

}




/**
 * MENU
 */
export const APP_MENU: MenuItemVM[] = [
  // { type: MENU_ITEM_TYPES.HEADER, text: 'NAVIGATION'},

  {type: MENU_ITEM_TYPES.ROUTE, text: 'Dashboard', uri: '/home', icon: 'fas fa-home', activeUriSegment: '/home', claims: [CLAIMS.USER, CLAIMS.PRODUCER, CLAIMS.ADMIN, CLAIMS.STUDIO]},
  // HOME
  { type: MENU_ITEM_TYPES.ROUTE, text: 'Dashboard', uri: '/home', icon: 'fas fa-home', activeUriSegment: '/home', exact: true},
  //{ type: MENU_ITEM_TYPES.ROUTE, text: 'Audition Callboard', uri: APP_ROUTE_NAMES.LISTING, icon: 'fas fa-list', activeUriSegment: APP_ROUTE_NAMES.LISTING, exact: false, claims: [CLAIMS.USER]},
  
  // Producer Stuff
  { type: MENU_ITEM_TYPES.ROUTE, text: 'My Projects', uri: APP_ROUTE_NAMES.PROJECT, icon: 'fas fa-theater-masks', activeUriSegment: APP_ROUTE_NAMES.PROJECT, exact: false, claims: [CLAIMS.PRODUCER]},
  { type: MENU_ITEM_TYPES.SUBMENU, text: 'Casting', icon: 'fas fa-clipboard-list', uri: 'casting', exact: true, activeUriSegment: 'casting', claims: [CLAIMS.PRODUCER], childRoutes: [
    { type: MENU_ITEM_TYPES.ROUTE, text: 'Auditions', uri: APP_ROUTE_NAMES.PROJECT_AUDITION, icon: 'fas fa-clipboard', activeUriSegment: APP_ROUTE_NAMES.PROJECT_AUDITION, exact: false, claims: [CLAIMS.PRODUCER]},
    { type: MENU_ITEM_TYPES.ROUTE, text: 'Listings', uri: APP_ROUTE_NAMES.LISTING, icon: 'fas fa-list', activeUriSegment: APP_ROUTE_NAMES.LISTING, exact: false, claims: [CLAIMS.PRODUCER]}
  ]},
  { type: MENU_ITEM_TYPES.ROUTE, text: 'Actor Database', uri: APP_ROUTE_NAMES.PRODUCER + '/' + PRODUCER_ROUTE_NAMES.MYCUSTOMLISTS, icon: 'fas fa-address-book', activeUriSegment: APP_ROUTE_NAMES.PRODUCER + '/' +PRODUCER_ROUTE_NAMES.MYCUSTOMLISTS, exact: false, claims: [CLAIMS.PRODUCER]},

  // Actor Stuff
  { type: MENU_ITEM_TYPES.ROUTE, text: 'My Book', uri: APP_ROUTE_NAMES.ACTOR+'/'+ACTOR_ROUTE_NAMES.MYBOOK, icon: 'fas fa-book', activeUriSegment: APP_ROUTE_NAMES.LISTING, exact: false, claims: [CLAIMS.USER]},

  // Studio Stuff
  { type: MENU_ITEM_TYPES.ROUTE, text: 'Studio Locations', uri: APP_ROUTE_NAMES.STUDIO +'/'+ STUDIO_ROUTE_NAMES.STUDIOLOCATIONLIST, icon: 'fas fa-building', activeUriSegment: APP_ROUTE_NAMES.STUDIO, exact: false, claims: [CLAIMS.STUDIO]},
  
  { type: MENU_ITEM_TYPES.ROUTE, text: 'Booking Schedule', uri: `${APP_ROUTE_NAMES.STUDIO_BOOKING}`, icon: 'fas fa-calendar', activeUriSegment: APP_ROUTE_NAMES.STUDIO_BOOKING, exact: false, claims: [CLAIMS.STUDIO] },

  {type: MENU_ITEM_TYPES.DIVIDER, text: '', claims: [CLAIMS.USER]},

  {type: MENU_ITEM_TYPES.DIVIDER, text: '', claims: [CLAIMS.PRODUCER]},

  {type: MENU_ITEM_TYPES.DIVIDER, text: '', claims: [CLAIMS.STUDIO]},


  {type: MENU_ITEM_TYPES.ROUTE, text: 'Callboard', uri: '/callboard', icon: 'fas fa-list-alt', activeUriSegment: '/callboard', exact: true, claims: [CLAIMS.USER, CLAIMS.PRODUCER, CLAIMS.ADMIN, CLAIMS.STUDIO]},
  {type: MENU_ITEM_TYPES.ROUTE, text: 'Studio Booking', uri: '/booking', icon: 'fas fa-key', activeUriSegment: '/booking', claims: [CLAIMS.USER, CLAIMS.PRODUCER, CLAIMS.ADMIN, CLAIMS.STUDIO]},

  // todo CRS | 2022-03-31:  move "My Profile" to a button inside "My Book" or maybe even in the dropdown menu, 
  { type: MENU_ITEM_TYPES.HEADER, text: 'ADMIN', claims: [CLAIMS.ADMIN]},
  { type: MENU_ITEM_TYPES.ROUTE, text: 'Users', uri: APP_ROUTE_NAMES.USER, icon: 'fas fa-user-circle', activeUriSegment: APP_ROUTE_NAMES.USER, exact: false, claims: [CLAIMS.ADMIN] },
  { type: MENU_ITEM_TYPES.ROUTE, text: 'App Settings', uri: APP_ROUTE_NAMES.SETTINGS, icon: 'fas fa-sliders-h', activeUriSegment: APP_ROUTE_NAMES.SETTINGS, exact: true, claims: [CLAIMS.ADMIN] },

];
