import { Component, Input, HostBinding } from '@angular/core';
import { AppService } from '@app/app.service';
import { LayoutService } from '../layout.service';
import * as firebase from 'firebase/firebase-auth';
import { AuthService } from '@app/+auth/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';


@Component({
  selector: 'app-layout-qdup-navbar',
  templateUrl: './layout-qdup-navbar.component.html',
  styles: [':host { display: block; }']
})
export class LayoutQdupNavbarComponent {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  isExpanded = false;
  isRTL: boolean;

  userData: firebase.User;

  loading: boolean;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-qdup-navbar') hostClassMain = true;

  constructor(private appService: AppService, private layoutService: LayoutService, private authService: AuthService) {
    this.isRTL = appService.isRTL;

    this.loading = true;

    this.authService.getCurrentUser$().pipe(takeUntil(this.destroyed$)).subscribe(
      (user) => {
        this.userData = user;
        this.loading = false;
      }
    );
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
