import { Component, HostBinding, OnDestroy } from '@angular/core';
import { AdminSettingsService } from '@app/+settings/services/admin-settings.service';
import { AppService } from '@app/app.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout-footer-admin',
  templateUrl: './layout-footer-admin.component.html',
  styles: [':host { display: block; }']
})
export class LayoutFooterAdminComponent implements OnDestroy {
  @HostBinding('class.layout-footer') hostClassMain = true;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  currentVersion: string;

  constructor(private appService: AppService, private settingsService: AdminSettingsService, private toastr: ToastrService) {

    this.settingsService.get('version').pipe(takeUntil(this.destroyed$)).subscribe(
      (version) => {
        if (!version) {
          const t = new Date();
          version = { timestamp: t };
        }
        if (!this.currentVersion) {
          this.currentVersion = version.timestamp;
        } else {

          if (version.timestamp !== this.currentVersion) {
            this.toastr.clear();
            this.toastr.info('Please click here to apply the update.', 'A new update is available!', { disableTimeOut: true, tapToDismiss: false }).onTap
              .pipe(take(1))
              .subscribe(() => { window.location.reload(); });
          }
        }
      }
    );
  }


  currentBg() {
    return `bg-${this.appService.layoutFooterBg}`;
  }


  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
