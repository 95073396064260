
import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BaseCrudService } from '@app/shared/repositories/+firestore/services/crud.base.service';

/**
 * Handles multiple types of app level configurations and settings. Return type designated on the individual functions
 */
@Injectable({
  providedIn: 'root',
})
export class AdminSettingsService extends BaseCrudService<any> {

  constructor(afs: AngularFirestore, zone: NgZone) {
    super('config', afs, zone);
  }
}
