import { IBaseCrudService } from '../models/crud.interface';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { IFirestoreQueryParam } from '../models/firestore-query-param.model';
import { IBaseModel } from '../models/base.model';
import { NgZone } from '@angular/core';

export abstract class BaseCrudService<T extends IBaseModel> implements IBaseCrudService<T> {

  protected collection: AngularFirestoreCollection<T>;

  constructor(path: string, protected afs: AngularFirestore, private ngZone: NgZone) {
    this.collection = this.afs.collection(path);
  }

  get(identifier: string): Observable<T> {
    return this.collection
      .doc<T>(identifier)
      .snapshotChanges()
      .pipe(
        map(doc => {
          if (doc.payload.exists) {
            const data = doc.payload.data() as any;
            const id = doc.payload.id;
            //return { id, ...data }; // idk why it was being done this way
            data.id = id;
            return data;
          }
        })
      );
  }


  list(): Observable<T[]> {
    return this.collection
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as T;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }


  /**
 * Query against collection items.
 *
 * ! onSnapshot returns void which trips the Angular cycle when the callback is triggered. To hook back in, we use ngZone.run to notify Angular change detection that new changes have been found and updated results are available.
 * @param params Query parameters
 * @returns Observable of results
 */
  query(params: IFirestoreQueryParam[]): Observable<T[]> {
    const param = params.splice(0, 1)[0];
    let query = this.collection.ref.where(param.field, param.operator, param.value);

    _.each(params, (p) => {
      query = query.where(p.field, p.operator, p.value);
    });

    return new Observable((observer) => {
      query.onSnapshot(snapshot => {
        const items = snapshot.docs.map(doc => {
          const data = doc.data() as T;
          data.id = doc.id;
          return data;
        });
        this.ngZone.run(() => {
          observer.next(items);
        });
      },
      (error) => {
        this.ngZone.run(() => {
          observer.error(error);
        });
      });
    });
  }


  add(item: T): Promise<T> {
    const promise = new Promise<T>((resolve) => {
      this.collection.add(item).then(ref => {
        const newItem = item;
        newItem.id = ref.id;
        /*const newItem = {
          id: ref.id,
          ...(item as any)
        }; */
        resolve(newItem);
      });
    });
    return promise;
  }


  update(id: string, item: T): Promise<T> {
    const promise = new Promise<T>((resolve) => {
      this.collection
        .doc<T>(id)
        .update(item)
        .then(() => {
          const newItem = item;
          newItem.id = id;
          resolve(newItem);
        });
    });
    return promise;
  }

  set(id: string, item: T): Promise<T> {
    const promise = new Promise<T>((resolve) => {
      this.collection
        .doc<T>(id)
        .set(item)
        .then(() => {
          resolve({
            ...(item as any)
          });
        });
    });
    return promise;
  }

  delete(id: string): Promise<void> {
    const docRef = this.collection.doc<T>(id);
    return docRef.delete();
  }

}
