import { Component, Input, AfterViewInit, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { LayoutService } from '../layout.service';
import { AuthService } from '@app/+auth/services/auth.service';
import { APP_MENU } from '@app/app.menu';
import { IBrandConfig } from '@app/+settings/models/brand-config.model';
import { AdminSettingsService } from '@app/+settings/services/admin-settings.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-layout-sidenav-admin',
  templateUrl: './layout-sidenav-admin.component.html',
  styles: [':host { display: block; } .app-brand.demo {height: 60px !important; }']
})
export class LayoutSidenavAdminComponent implements AfterViewInit, OnDestroy {
  @Input() orientation = 'vertical';

  @HostBinding('class.layout-sidenav') hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') hostClassFlex = false;

  menu: any[];
  appConfig: IBrandConfig;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private router: Router, private appService: AppService, private layoutService: LayoutService, private authService: AuthService, private settingsService: AdminSettingsService) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;

    this.authService.userTokenResult$.subscribe(
      tokenResult => {
        if (tokenResult) {
          // Build Menu
          this.menu = [];

          // User Found
          _.each(APP_MENU, (item) => {
            let valid = false;

            _.each(item.claims, (c) => {
              if (!valid) {
                valid = !!tokenResult.claims[c];
              }
            });

            if (valid)
              this.menu.push(item);
          });
        }
      },
      () => {
        // No user
        this.menu = _.filter(APP_MENU, (i) => { return !i.claims; });
      }
    );

    // Pull App Settings
    this.settingsService.get('brand').pipe(takeUntil(this.destroyed$)).subscribe(
      (appConfig) => {
        this.appConfig = appConfig;
      }
    );
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  }

  isActive(url: string, active: boolean) {
    return this.router.isActive(url, active);
  }

  isMenuActive(url: string, active: boolean) {
    return this.router.isActive(url, active);
  }

  isMenuOpen(url: string, active: boolean) {
    return this.router.isActive(url, active) && this.orientation !== 'horizontal';
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
