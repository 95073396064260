export const APP_ROUTE_NAMES = {
  DEFAULT: 'home',
  AUTH: 'identity',
  ACCOUNT: 'account',
  USER: 'user',
  MY_PROFILE: 'my-profile',
  SETTINGS: 'settings',
  LISTING: 'listing',
  PROJECT: 'project',
  ACTOR: 'a',
  PRODUCER: 'p',
  MY_ACCOUNT: 'my-account',
  SITE_PAGES: '',
  COMING_SOON: 'coming-soon',
  PROJECT_AUDITION: 'auditions',
  PROJECT_LISTING: 'project-listing',
  CALLBOARD: 'callboard',
  STUDIO: 'studio',
  STUDIO_BOOKING: 'studio-booking',
  STUDIO_BOOKING_WIZARD: 'booking',
  CONTACT: 'contact-submissions',
};