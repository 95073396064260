import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from 'src/templates/not-found/not-found.component';

// *******************************************************************************
// Layouts

import { Layout2AdminComponent } from 'src/templates/layout/layout-2-admin/layout-2-admin.component';
import { APP_ROUTE_NAMES } from './app.routes.names';
import { LayoutBlankComponent } from 'src/templates/layout/layout-blank/layout-blank.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { GUARD_PIPES } from './+auth/guards/guard-pipes.const';
import { AUTH_ROUTE_NAMES } from './+auth/routes/auth.routes.names';
import { LayoutQdupFrontComponent } from 'src/templates/layout/layout-qdup-front/layout-qdup-front.component';
import { LayoutQdupFrontFullComponent } from 'src/templates/layout/layout-qdup-front-full/layout-qdup-front-full.component';

// *******************************************************************************
// Routes

const routes: Routes = [
  {
    //path: '', redirectTo: APP_ROUTE_NAMES.DEFAULT, pathMatch: 'full'
    path: '',
    component: LayoutQdupFrontFullComponent,
    children: [
      { path: '', loadChildren: () => import('./+coming-soon/coming-soon.module').then(m => m.ComingSoonModule) },
    ]
  },
  // todo CRS | 2022-04-01: add a route for site-pages, use LayoutNavComponent
  {
    path: APP_ROUTE_NAMES.DEFAULT,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin },
    pathMatch: 'full',
    children: [
      { path: '', loadChildren: () => import('./+home/home.module').then(m => m.HomeModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.AUTH,
    component: LayoutBlankComponent,
    children: [
      { path: '', loadChildren: () => import('./+auth/auth.module').then(m => m.AuthModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.ACCOUNT,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin },
    children: [
      { path: '', loadChildren: () => import('./+auth/profile.module').then(m => m.ProfileModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.USER,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin },
    children: [
      { path: '', loadChildren: () => import('./+user/user.module').then(m => m.UserModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.SETTINGS,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin },
    children: [
      { path: '', loadChildren: () => import('./+settings/settings.module').then(m => m.SettingsModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.LISTING,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+listing/listing.module').then(m => m.ListingModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.PROJECT,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+project/project.module').then(m => m.ProjectModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.ACTOR,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+actor/actor.module').then(m => m.ActorModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.PRODUCER,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+producer/producer.module').then(m => m.ProducerModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.MY_PROFILE,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+my-profile/my-profile.module').then(m => m.MyProfileModule) },
    ]
  },
  {
    path: 'login', redirectTo: APP_ROUTE_NAMES.AUTH + '/' + AUTH_ROUTE_NAMES.LOGIN, pathMatch: 'full'
  },
  {
    path: APP_ROUTE_NAMES.MY_ACCOUNT,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+my-account/my-account.module').then(m => m.MyAccountModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.SITE_PAGES,
    component: LayoutQdupFrontComponent,
    children: [
      { path: '', loadChildren: () => import('./+site-pages/site-pages.module').then(m => m.SitePagesModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.PROJECT_AUDITION,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+project-audition/project-audition.module').then(m => m.ProjectAuditionModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.PROJECT_LISTING,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+project-listing/project-listing.module').then(m => m.ProjectListingModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.CALLBOARD,
    component: LayoutQdupFrontComponent,
    children: [
      { path: '', loadChildren: () => import('./+callboard/callboard.module').then(m => m.CallboardModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.STUDIO,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+studio/studio.module').then(m => m.StudioModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.STUDIO_BOOKING,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+studio-booking/studio-booking.module').then(m => m.StudioBookingModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.STUDIO_BOOKING_WIZARD,
    component: LayoutQdupFrontComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+studio-booking-wizard/studio-booking-wizard.module').then(m => m.StudioBookingWizardModule) },
    ]
  },
  {
    path: APP_ROUTE_NAMES.CONTACT,
    component: Layout2AdminComponent,
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: GUARD_PIPES.redirectUnauthorizedToLogin }, children: [
      { path: '', loadChildren: () => import('./+contact/contact.module').then(m => m.ContactModule) },
    ]
  },
  /*APP_ROUTE*/

  // 404 Not Found page
  { path: '**', component: NotFoundComponent }
];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
