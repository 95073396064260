import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


// *******************************************************************************
// Layouts

import { Layout1Component } from './layout-1/layout-1.component';
import { Layout1FlexComponent } from './layout-1-flex/layout-1-flex.component';
import { Layout2AdminComponent } from './layout-2-admin/layout-2-admin.component';
import { Layout2FlexComponent } from './layout-2-flex/layout-2-flex.component';
import { LayoutWithoutNavbarComponent } from './layout-without-navbar/layout-without-navbar.component';
import { LayoutWithoutNavbarFlexComponent } from './layout-without-navbar-flex/layout-without-navbar-flex.component';
import { LayoutWithoutSidenavComponent } from './layout-without-sidenav/layout-without-sidenav.component';
import { LayoutHorizontalSidenavComponent } from './layout-horizontal-sidenav/layout-horizontal-sidenav.component';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';

import { LayoutQdupFrontComponent } from './layout-qdup-front/layout-qdup-front.component';


// *******************************************************************************
// Components

import { LayoutNavbarAdminComponent } from './layout-navbar-admin/layout-navbar-admin.component';
import { LayoutSidenavAdminComponent } from './layout-sidenav-admin/layout-sidenav-admin.component';
import { LayoutFooterAdminComponent } from './layout-footer-admin/layout-footer-admin.component';


// *******************************************************************************
// Libs

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavModule } from '../../assets/libs/sidenav/sidenav.module';


// *******************************************************************************
// Services

import { LayoutService } from './layout.service';
import { Layout2Component } from './layout-2/layout-2.component';
import { LayoutNavbarComponent } from './layout-navbar/layout-navbar.component';
import { LayoutSidenavComponent } from './layout-sidenav/layout-sidenav.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { LayoutQdupSidenavFrontComponent } from './layout-qdup-sidenav-front/layout-qdup-sidenav-front.component';
import { LayoutQdupNavbarComponent } from './layout-qdup-navbar/layout-qdup-navbar.component';
import { LayoutQdupMenuFrontComponent } from './layout-qdup-menu-front/layout-qdup-menu-front.component';
import { LayoutQdupFrontFullComponent } from './layout-qdup-front-full/layout-qdup-front-full.component';


// *******************************************************************************
//

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SidenavModule
  ],
  declarations: [
    LayoutNavbarComponent,
    LayoutSidenavComponent,
    LayoutFooterComponent,
    Layout1Component,
    Layout1FlexComponent,
    Layout2Component,
    Layout2FlexComponent,
    LayoutWithoutNavbarComponent,
    LayoutWithoutNavbarFlexComponent,
    LayoutWithoutSidenavComponent,
    LayoutHorizontalSidenavComponent,
    LayoutBlankComponent,

    LayoutQdupFrontComponent,
    LayoutQdupSidenavFrontComponent,
    LayoutQdupNavbarComponent,
    LayoutQdupMenuFrontComponent,
    LayoutQdupFrontFullComponent,

    Layout2AdminComponent,
    LayoutNavbarAdminComponent,
    LayoutSidenavAdminComponent,
    LayoutFooterAdminComponent
  ],
  providers: [
    LayoutService
  ]
})
export class LayoutModule { }
