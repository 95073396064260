import { Component } from '@angular/core';

@Component({
  template: `
   <div class="block-ui-template">
    <div class="sk-wave sk-primary mx-auto"><div class="sk-wave-rect"></div><div class="sk-wave-rect"></div><div class="sk-wave-rect"></div><div class="sk-wave-rect"></div><div class="sk-wave-rect"></div></div>
  </div>
  `,
})
export class BlockTemplateCmp { }
