import { CLAIMS } from './+auth/constants/claims.const';


const MENU_ITEM_TYPES = {
  HEADER: 'header',
  ROUTE: 'route',
  DIVIDER: 'divider',
  SUBMENU: 'submenu'
};

class MenuItemVM {
  type: string;
  text: string;
  uri?: string;
  icon?: string;
  activeUriSegment?: string;
  exact?: boolean;

  childRoutes?: MenuItemVM[];

  claims?: CLAIMS[];

}




/**
 * MENU
 */
export const APP_MENU_FRONT: MenuItemVM[] = [
  // { type: MENU_ITEM_TYPES.HEADER, text: 'NAVIGATION'},

  //{type: MENU_ITEM_TYPES.ROUTE, text: 'Callboard', uri: '/callboard', activeUriSegment: '/callboard'},
  //{type: MENU_ITEM_TYPES.ROUTE, text: 'Studio Booking', uri: '/booking', activeUriSegment: '/booking'},
  //{type: MENU_ITEM_TYPES.ROUTE, text: 'About Us', uri: '/about-us', activeUriSegment: '/about-us'},
  //{type: MENU_ITEM_TYPES.ROUTE, text: 'Pricing', uri: '/pricing', activeUriSegment: '/pricing'},
  {type: MENU_ITEM_TYPES.ROUTE, text: 'Contact', uri: '/contact', activeUriSegment: '/contact'},
];
