import { Component, Input, AfterViewInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { IBrandConfig } from '@app/+settings/models/brand-config.model';
import { AdminSettingsService } from '@app/+settings/services/admin-settings.service';
import { AppService } from '@app/app.service';
import { first } from 'rxjs/operators';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-layout-qdup-sidenav-front',
  templateUrl: './layout-qdup-sidenav-front.component.html',
  styles: [':host { display: block; }']
})
export class LayoutQdupSidenavFrontComponent implements AfterViewInit {
  @Input() orientation = 'vertical';

  @HostBinding('class.layout-sidenav') hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') hostClassFlex = false;

  appConfig: IBrandConfig;

  constructor(private router: Router, private appService: AppService, private layoutService: LayoutService, private settingsService: AdminSettingsService) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;

    // Pull App Settings
    this.settingsService.get('brand').pipe(first()).subscribe(
      (appConfig) => {
        this.appConfig = appConfig;
      }
    );
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
  }

  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg} d-lg-none`;
  }

  isActive(url) {
    return this.router.isActive(url, true);
  }

  isMenuActive(url) {
    return this.router.isActive(url, false);
  }

  isMenuOpen(url) {
    return this.router.isActive(url, false) && this.orientation !== 'horizontal';
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
