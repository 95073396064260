import { redirectUnauthorizedTo, redirectLoggedInTo, customClaims } from '@angular/fire/auth-guard';
import { AUTH_ROUTE_NAMES } from '../routes/auth.routes.names';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_ROUTE_NAMES } from '@app/app.routes.names';

export const GUARD_PIPES = {
  none: () => { return true; },
  ownProfile: (next) => map((user: any) => next.params.uid === user.uid),

  // *: Claims Based
  restrictToPermission_ADMIN: () => pipe(customClaims, map(claims => claims.ADMIN)),

  // *: Redirects
  redirectUnauthorizedToLogin: () => redirectUnauthorizedTo(['/', APP_ROUTE_NAMES.AUTH, AUTH_ROUTE_NAMES.LOGIN]),
  redirectAuthorizedToHome: () => redirectLoggedInTo(['/', APP_ROUTE_NAMES.DEFAULT]) //, APP_ROUTE_NAMES.TIME, TIME_ROUTE_NAMES.ENTER
};
