<div class="d-flex qdup-menu-front" [ngClass]="{'flex-column' : orientation == 'vertical', 'justify-content-between': orientation == 'horizontal'}">

<ng-container *ngFor="let item of menu">

      <!-- Divider -->
      <div *ngIf="item.type == 'divider'"
           class="sidenav-inner"
           [ngClass]="{ 'py-1': orientation !== 'horizontal' }"></div>

      <!-- Header -->
      <sidenav-header *ngIf="item.type == 'header'"
                      class="small font-weight-semibold">{{item.text}}</sidenav-header>

      <!-- Single Nav Link -->
      <sidenav-router-link *ngIf="item.type === 'route'"
                           icon="{{item.icon}}"
                           route="{{'/' + item.uri}}"
                           [active]="isMenuActive(item.activeUriSegment, item.exact)">{{item.text}}
      </sidenav-router-link>

      <!-- Submenu -->
      <sidenav-menu *ngIf="item.type === 'submenu'"
                    icon="{{item.icon}}"
                    text="{{item.text}}"
                    [active]="isMenuActive(item.activeUriSegment, item.exact)"
                    [open]="isMenuOpen(item.activeUriSegment, item.exact)">

        <!-- Submenu Link -->
        <sidenav-router-link *ngFor="let child of item.childRoutes"
                             route="{{'/' + child.uri}}"
                             [active]="isActive(child.activeUriSegment, child.exact)">{{child.text}}
        </sidenav-router-link>
      </sidenav-menu>

    </ng-container>

  </div>