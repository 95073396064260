<sidenav [orientation]="orientation" [ngClass]="getClasses()">
  <!-- Brand demo (see src/demo.css) -->
  <div class="app-brand demo "
       *ngIf="orientation !== 'horizontal'">
    <div class="app-brand-logo demo text-primary">

      <img [src]="appConfig.faviconURL || 'assets/img/logo.png'"
           *ngIf="appConfig && appConfig.faviconURL"
           alt=""
           class="img-fluid">
      <div class="spinner-grow spinner-grow text-primary"
           *ngIf="!appConfig || !appConfig.faviconURL"
           role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <a routerLink="/"
       class="app-brand-text demo sidenav-text font-weight-normal ml-2">{{appConfig?.appName || 'AppWorks'}}</a>
    <a href="javascript:void(0)"
       class="layout-sidenav-toggle sidenav-link text-large ml-auto"
       (click)="toggleSidenav()">
      <i class="ion ion-md-menu align-middle"></i>
    </a>
  </div>
  <div class="sidenav-divider mt-0" *ngIf="orientation !== 'horizontal'"></div>

  <!-- Links -->
  <div class="sidenav-inner w-100" [ngClass]="{ 'py-1': orientation !== 'horizontal' }">

    <!-- Dashboards -->
    <app-layout-qdup-menu-front [orientation]="'vertical'"></app-layout-qdup-menu-front>


  </div>
</sidenav>
