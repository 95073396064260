import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { APP_MENU_FRONT } from '@app/app.menu-front';
import { AppService } from '@app/app.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-layout-qdup-menu-front',
  templateUrl: './layout-qdup-menu-front.component.html',
  styles: [':host { display: block; }']
})
export class LayoutQdupMenuFrontComponent {
  @Input() orientation = 'horizontal';
  @HostBinding('class.w-100') hostClassMain = true;


  menu: any[];
  constructor(private appService: AppService, private router: Router) {
    this.menu = [];

    // User Found
    _.each(APP_MENU_FRONT, (item) => {



      this.menu.push(item);
    });
  }

  currentBg() {
    return `bg-${this.appService.layoutFooterBg}`;
  }

  isMenuActive(url: string, active: boolean) {
    return this.router.isActive(url, active);
  }
  isMenuOpen(url: string, active: boolean) {
    return this.router.isActive(url, active) && this.orientation !== 'horizontal';
  }
  isActive(url: string, active: boolean) {
    return this.router.isActive(url, active);
  }
}
