<nav class="footer" [ngClass]="currentBg()">
  <div class="container-fluid d-flex flex-wrap justify-content-between align-items-center text-center container-p-x pb-3">
    <div class="pt-3">
      <img class="d-inline mr-2" src="assets/img/qdup-icon-4.png" width="30px" height="30px"/>
      <span class="footer-text font-weight-bolder">Qdup</span> © 2022
    </div>
    <div>
      <a href="javascript:void(0)" class="footer-link pt-3 d-none">About Us</a>
      <a href="javascript:void(0)" class="footer-link pt-3 ml-4">Contact</a>
      <a href="javascript:void(0)" class="footer-link pt-3 ml-4 d-none">Terms &amp; Conditions</a>
    </div>
  </div>
</nav>
