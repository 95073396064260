// *: Claims IDS
export enum CLAIMS {
  ADMIN = 'ADMIN',
  STUDIO = 'STUDIO',
  PRODUCER = 'PRODUCER',
  USER = 'USER'
}

// *: List of all claims
export const CLAIMS_LIST = [
  CLAIMS.ADMIN,
  CLAIMS.STUDIO,
  CLAIMS.PRODUCER,
  CLAIMS.USER
];

// *: Description for claims
export const CLAIM_DESC = {
  ADMIN: 'Manage App configurations and User accounts.',
  STUDIO: 'Create and manage studio locations, calendar availability, and billing',
  PRODUCER: 'Create and manage custom actor lists, projects, and audition listings',
  USER: 'Manage Profile, Upload Materials, and apply to auditions'
};
