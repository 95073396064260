<nav class="navbar navbar-expand align-items-center container-p-x" [ngClass]="currentBg()">

  <!-- Branding -->
  <a routerLink="/" class="navbar-brand app-brand demo py-0 mr-3 d-flex">
      <img src="assets/img/qdup-logo-tp5-slim2.png" class="d-inline-block ui-w-100 mb-0 w-100"/>
  </a>

  <!-- Sidenav toggle for mobile -->
  <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto" *ngIf="sidenavToggle">
    <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" (click)="toggleSidenav()">
      <i class="ion ion-md-menu text-large align-middle"></i>
    </a>
  </div>
  <div class="navbar-nav d-none d-lg-block ml-auto">
    <app-layout-qdup-menu-front [orientation]="'horizontal'"></app-layout-qdup-menu-front>
  </div>

  <div class="navbar-nav align-items-center" *ngIf="userData">

    <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-user nav-item">
      <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
          <div [style]="'background-image: url(\'' + userData?.photoURL + '\')'"
          alt
          class="d-block top-bar-user-image rounded-circle">
          </div>          
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{userData ? userData.displayName : 'Loading...'}}</span>
        </span>
      </a>
      <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
        <a [routerLink]="'/home'" class="dropdown-item"><i class="fas fa-home text-lightest"></i> &nbsp; Dashboard</a>
        <a [routerLink]="'/my-profile'" class="dropdown-item"><i class="fas fa-user text-lightest"></i> &nbsp; My profile</a>
        <!--<a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</a>-->
        <a [routerLink]="'/my-account'" class="dropdown-item"><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</a>
        <div class="dropdown-divider"></div>
        <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</a>
      </div>
    </div>
  </div>
  <div class="ml-auto d-none" *ngIf="!loading && !userData">
    <button  [routerLink]="['/identity', 'login']" class="btn btn-outline-primary">Login</button>
  </div>
</nav>
