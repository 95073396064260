import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from '@app/+auth/services/auth.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-layout-2-admin',
  templateUrl: './layout-2-admin.component.html',
  styles: [':host { display: block; }', ':host ::ng-deep .layout-loading .sidenav-link { transition: none !important; }']
})
export class Layout2AdminComponent implements AfterViewInit, OnDestroy {
  // Prevent "blink" effect
  public initialized = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private layoutService: LayoutService, private authService: AuthService) { }

  ngAfterViewInit() {
    this.authService.getCurrentUser$().pipe(takeUntil(this.destroyed$)).subscribe(
      (user) => {
        if (user) {
          this.authService.userTokenResult$.pipe(takeUntil(this.destroyed$)).subscribe((token) => {
            if (token) {
              setTimeout(() => {
                this.initialized = true;

                this.layoutService.init();
                this.layoutService.update();
                this.layoutService.setAutoUpdate(true);
              });
            }
          });

        }
      }
    );
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.layoutService.destroy();
      this.destroyed$.next(true);
      this.destroyed$.complete();
    });
  }

  closeSidenav() {
    setTimeout(() => {
      this.layoutService.setCollapsed(true);
    });
  }
}
