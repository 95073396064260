<sidenav [orientation]="orientation"
         [ngClass]="getClasses()">

  <!-- Image Based Logo -->
  <div class="app-brand demo "
       *ngIf="orientation !== 'horizontal'">
    <div class="app-brand-logo demo text-primary">

      <img [src]="appConfig.faviconURL || 'assets/img/logo.png'"
           *ngIf="appConfig && appConfig.faviconURL"
           alt=""
           class="img-fluid">
      <div class="spinner-grow spinner-grow text-primary"
           *ngIf="!appConfig || !appConfig.faviconURL"
           role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <a routerLink="/"
       class="app-brand-text demo sidenav-text font-weight-normal ml-2">{{appConfig?.appName || 'AppWorks'}}</a>
    <a href="javascript:void(0)"
       class="layout-sidenav-toggle sidenav-link text-large ml-auto"
       (click)="toggleSidenav()">
      <i class="ion ion-md-menu align-middle"></i>
    </a>
  </div>

  <div class="sidenav-divider mt-0"
       *ngIf="orientation !== 'horizontal'"></div>

  <!-- Inner -->
  <div class="sidenav-inner"
       [ngClass]="{ 'py-1': orientation !== 'horizontal' }">

    <ng-container *ngFor="let item of menu">

      <!-- Divider -->
      <div *ngIf="item.type == 'divider'"
           class="sidenav-inner"
           [ngClass]="{ 'py-1': orientation !== 'horizontal' }"></div>

      <!-- Header -->
      <sidenav-header *ngIf="item.type == 'header'"
                      class="small font-weight-semibold">{{item.text}}</sidenav-header>

      <!-- Single Nav Link -->
      <sidenav-router-link *ngIf="item.type === 'route'"
                           icon="{{item.icon}}"
                           route="{{'/' + item.uri}}"
                           [active]="isMenuActive(item.activeUriSegment, item.exact)">{{item.text}}
      </sidenav-router-link>

      <!-- Submenu -->
      <sidenav-menu *ngIf="item.type === 'submenu'"
                    icon="{{item.icon}}"
                    text="{{item.text}}"
                    [active]="isMenuActive(item.activeUriSegment, item.exact)"
                    [open]="isMenuOpen(item.activeUriSegment, item.exact)">

        <!-- Submenu Link -->
        <sidenav-router-link *ngFor="let child of item.childRoutes"
                             route="{{'/' + child.uri}}"
                             [active]="isActive(child.activeUriSegment, child.exact)">{{child.text}}
        </sidenav-router-link>
      </sidenav-menu>

    </ng-container>
  </div>
</sidenav>
